<template>
  <div class="vg_wrapper">
    <el-card>
      <div class="vd_search_group">
        <el-form :inline="true" size="min" :model="searchForm" ref="searchForm" label-width="120px" @keyup.enter.native="getMqcdsNow()">
          <el-row>
            <el-col :md="8">
              <el-form-item label="材料编号：">
                <el-input size="small" v-model.trim="searchForm.mdrl_no" clearable placeholder="请填写材料编号"></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="材料名称：">
                <el-input size="small" v-model.trim="searchForm.mdrl_name" clearable placeholder="请填写材料名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="库位：">
                <el-select size="small" filterable v-model="searchForm.mqcd_bin" placeholder="请选择库位" clearable>
                  <el-option v-for="item in binList" :key="item.id" :label="item.param1" :value="item.param1"> </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :md="8">
              <el-form-item label="材料类型：">
                <el-select size="small" v-model="searchForm.mdrl_type" placeholder="请选择材料类型" clearable>
                  <el-option v-for="item in mdrlType" :key="item.id" :label="item.label" :value="item.id"> </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="16">
              <el-form-item label="时间范围：">
                <div class="block vg_mr_32">
                  <el-date-picker
                    size="small"
                    v-model="searchForm.timeValue"
                    type="datetimerange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                  >
                  </el-date-picker>
                </div>
              </el-form-item>
              <el-form-item>
                <el-button size="small" type="primary" icon="el-icon-search" @click="getMqcdsNow()" class="vg_ml_16">查询</el-button>
                <el-button type="info" size="small" class="vd_export" @click="buttonRefresh()"
                  ><i class="el-icon-refresh-right"></i> 刷新</el-button
                >
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div class="vg_mtb_16">
        <el-button type="primary" size="small" @click="addRow()" :disabled="!btn.add"><i class="el-icon-plus"></i> 新增</el-button>
        <el-button type="danger" size="small" @click="doDelete()" :disabled="!btn.delete"><i class="el-icon-delete"></i> 删除</el-button>
        <el-button type="primary" size="small" class="vd_export" @click="print()"><i class="el-icon-s-promotion"></i> 打印</el-button>
        <el-button type="success" size="small" class="vd_export" @click="recycleBin()"><i class="el-icon-refresh"></i> 回收站</el-button>
      </div>
      <el-row>
        <el-col :md="24">
          <el-table
            ref="multiTable"
            :data="tableData"
            @selection-change="handleSelectionChange"
            @select="selectRows"
            @select-all="selectRows"
            border
            v-loading="loadFlag"
          >
            <el-table-column type="selection" width="48" align="center" />
            <el-table-column label="材料编号" prop="mdrl_no" />
            <el-table-column label="材料图片" align="center">
              <template v-slot="scope">
                <el-popover v-if="scope.row.imge_url" placement="right" width="400" trigger="hover">
                  <div>
                    <el-image style="width: 100%" class="vd_popimg" :src="formatPicO(scope, 'l')" fit="fill" />
                  </div>
                  <el-image slot="reference" class="vd_elimg" :src="formatPicO(scope, 's')" fit="fill"> </el-image>
                </el-popover>
                <span v-else class="vg_9f9a9a">暂无</span>
              </template>
            </el-table-column>
            <el-table-column label="二维码图片" align="center">
              <template v-slot="scope">
                <el-image class="vd_elimg" v-if="scope.row.mqcd_image" :src="formatPic(scope, 'l')" fit="fill"> </el-image>
                <span v-else class="vg_9f9a9a">暂无</span>
              </template>
            </el-table-column>
            <el-table-column label="二维码编号">
              <template v-slot="scope">
                <span @click="copySty(scope.row.mqcd_id)" class="vg_cursor">mt{{ scope.row.mqcd_id }}</span>
              </template>
            </el-table-column>
            <el-table-column label="材料名称" prop="mdrl_name" />
            <el-table-column label="材料类型" prop="mdrl_type" :formatter="formatLeavType" />
            <el-table-column label="材料规格" prop="mdrl_spec" />
            <el-table-column label="材料数量" prop="mqcd_num">
              <template v-slot="scope">
                <div v-if="scope.row.mqcd_num">
                  {{ scope.row.mqcd_num | formatMqcdNum }}
                </div>
                <div v-else class="vg_9f9a9a">0.0000</div>
              </template>
            </el-table-column>
            <el-table-column label="库位" prop="mqcd_bin">
              <template v-slot="scope">
                <span v-if="scope.row.mqcd_bin">
                  {{ scope.row.mqcd_bin }}
                </span>
                <span v-else class="vg_9f9a9a">暂无</span>
              </template>
            </el-table-column>
            <el-table-column label="录入人" prop="stff_name" show-overflow-tooltip :formatter="helper.personCombina" />
            <el-table-column label="创建时间" prop="create_time" :formatter="formatDate" />
          </el-table>
        </el-col>
      </el-row>

      <el-dialog title="" :visible.sync="dialogVisible" width="30%">
        <mqcdPrint @printCancel="printCancel" :qrcdPrint="mqcdPrint"></mqcdPrint>
      </el-dialog>
      <el-dialog title="" :visible.sync="recycleBinFlag" width="70%">
        <mqcdListHui ref="mqcdListHui" @confirmIn="confirmIn"></mqcdListHui>
      </el-dialog>
      <el-row>
        <el-col :md="24">
          <pubPagination :totalPage="totalPage" @changePageSearch="changePageSearch" ref="pubPagination"></pubPagination>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script>
import { get, post } from '@api/request';
import { mqcdAPI } from '@api/modules/mqcd';
import { optnAPI } from '@api/modules/optn';
import pubPagination from '@/components/common/pubPagination';
import mqcdPrint from './TabChild/Componet/MqcdPrint.vue';
import mqcdListHui from './TabChild/Componet/MqcdListHui.vue';
import helper from '@assets/js/helper.js';

export default {
  name: 'MqcdList',
  components: {
    pubPagination,
    mqcdPrint,
    mqcdListHui
  },
  data() {
    return {
      tableData: [],
      searchForm: {},
      totalPage: 0,
      btn: {},
      loadFlag: true,
      multiSelection: [],
      currentPage: 1,
      binList: [],
      mdrlType: [
        { id: 1, label: '主材料' },
        { id: 2, label: '辅材料' },
        { id: 3, label: '其他' }
      ],
      dialogVisible: false,
      mqcdPrint: [],
      recycleBinFlag: false,
      selectRefuDisabled: false,
      optnColorList: [],
      isDyj: true,
      beloDeptId: null
    };
  },
  created() {
    //this.getDepartment()
    this.initData();
  },
  filters: {
    formatMqcdNum(row) {
      return helper.haveFour(row);
    },
    formatMqcdMtrlThick(row) {
      return helper.reservedDigits(row);
    }
  },
  watch: {
    $route(to, from) {
      if (from.path === '/mqcd_add') {
        this.getMqcdsList();
      }
    }
  },
  methods: {
    initData() {
      this.loadFlag = true;
      this.getMqcdsList();
      this.getBinList();
    },
    // 获取库位信息
    getBinList() {
      get(optnAPI.getAllContent, { perm_id: 10006 }).then(res => {
        if (res.data.code === 0) {
          this.binList = res.data.data;
        }
      });
    },
    // 获取二维码信息
    getMqcdsList() {
      const timeNewVal = this.helper.getTime(this.searchForm.timeValue);
      this.searchForm.start_time = timeNewVal.startTime;
      this.searchForm.end_time = timeNewVal.endTime;
      this.searchForm.page_no = this.currentPage;
      get(mqcdAPI.getMqcds, this.searchForm).then(res => {
        if (res.data.code === 0) {
          this.loading = false;
          this.tableData = res.data.data.list;
          this.totalPage = res.data.data.total;
          this.btn = res.data.data.btn;
          setTimeout(() => {
            this.loadFlag = false;
          }, 500);
        }
      });
    },
    //判断是否 是DYJ员工
    getDepartment() {
      let userInfo = this.$cookies.get('userInfo');
      if(userInfo.user_name === 'admin'){
        this.searchForm.belo_dept_id = null;
        this.isDyj = false;
        return;
      }
      if (userInfo.dept_id === 2 || userInfo.dept_id === 17) {
        this.searchForm.belo_dept_id = userInfo.dept_id + '';
        this.beloDeptId = userInfo.dept_id + '';
        this.isDyj = true;
      } else {
        this.searchForm.belo_dept_id = null;
        this.isDyj = false;
      }
    },
    // 刷新
    buttonRefresh() {
      this.searchForm = {
        page_no: 1
      };
      if (this.isDyj) {
        this.searchForm.belo_dept_id = this.beloDeptId;
      } else {
        this.searchForm.belo_dept_id = null;
        this.$refs.selectSectionType.value2 = '全部';
      }
      this.loadFlag = true;
      this.currentPage = 1;
      this.initData();
      this.$refs.pubPagination.currentPage = 1;
    },
    // 关闭打印页面
    printCancel() {
      this.dialogVisible = false;
    },
    // 回收站打开页面
    recycleBin() {
      this.recycleBinFlag = true;
      setTimeout(() => {
        this.$refs.mqcdListHui.initData();
      }, 100);
    },
    // 关闭回收页面
    confirmIn() {
      this.recycleBinFlag = false;
      this.getMqcdsList();
    },
    // 打印
    print() {
      if (this.multiSelection.length > 0) {
        if (this.multiSelection.length <= 10) {
          this.mqcdPrint = this.multiSelection;
          this.dialogVisible = true;
        } else {
          this.$message({ message: '最多只能打印10条', type: 'warning' });
        }
      } else {
        this.$message({ message: '至少选择一条数据', type: 'warning' });
      }
    },
    // 查询方法
    getMqcdsNow() {
      this.loadFlag = true;
      this.$refs.pubPagination.currentPage = 1;
      this.currentPage = 1;
      this.initData();
    },
    // 状态查询
    getStatusVal(val) {
      this.searchForm.status = val;
    },
    // 多选
    handleSelectionChange(val) {
      this.multiSelection = val;
    },
    // 判断是否可以删除
    selectRows(selection) {
      this.btn.delete = this.helper.isDelete(selection);
    },
    // //新增
    addRow() {
      const permId = this.$route.query.perm_id;
      this.jump(`/mqcd_add?perm_id=${permId}`);
    },
    // 分页查询
    changePageSearch(val) {
      this.currentPage = val;
      this.getMqcdsList();
    },
    // 图片类型
    formatPic(scope, type) {
      return this.helper.picUrl(scope.row.mqcd_image, type, scope.row.create_time);
    },
    formatPicO(scope, type) {
      return this.helper.picUrl(scope.row.imge_url, type, scope.row.create_time);
    },
    // 材料类型
    formatLeavType(row) {
      if (row.mdrl_type === 1) {
        return '主材料';
      } else if (row.mdrl_type === 2) {
        return '辅材料';
      } else {
        return '其他';
      }
    },
    // 二维码编号复制
    copySty(val) {
      let str = 'md' + val;
      this.copy(str);
    },
    copy(data) {
      let url = data;
      let oInput = document.createElement('input');
      oInput.value = url;
      document.body.appendChild(oInput);
      oInput.select(); // 选择对象;
      document.execCommand('Copy'); // 执行浏览器复制命令
      this.$message({
        message: '复制成功',
        type: 'success'
      });
      oInput.remove();
    },
    // 二维码编号
    formaEr(row) {
      return 'mt' + row.mqcd_id;
    },
    formatLeavwidth(row) {
      return helper.haveFour(row.mdrl_width) + '米';
    },
    // 时间转换
    formatDate(row) {
      return this.helper.toStringDate(row.create_time);
    },
    // 未选中提示
    doDelete() {
      if (this.multiSelection.length === 0) {
        this.$message({ type: 'warning', message: '请至少选择一条数据！' });
      } else {
        this.mBox();
      }
    },
    //材料信息list删除提示
    mBox() {
      this.$confirm('确定删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          const ids = [];
          this.multiSelection.map(item => {
            ids.push(item.mqcd_id);
          });
          post(mqcdAPI.deleteMqcdByIds, { mqcd_id_list: ids })
            .then(res => {
              let mg = res.data.msg;
              if (res.data.code === 0) {
                let tp = 'success';
                this.$message({ message: mg, type: tp });
                if (ids.length === this.tableData.length) {
                  if (this.currentPage > 1) {
                    this.currentPage = this.currentPage - 1;
                    this.$refs.pubPagination.currentPage = this.currentPage;
                  }
                }
                this.getMqcdsList();
              } else {
                let tp = 'error';
                this.$message({ message: mg, type: tp });
                this.getMqcdsList();
              }
            })
            .catch(res => {
              let mg = res.data.msg;
              let tp = 'error';
              this.$message({ message: mg, type: tp });
              this.getMqcdsList();
            });
        })
        .catch(() => {
          let mg = '取消删除！';
          let tp = 'info';
          this.$message({ message: mg, type: tp });
        });
    },
    //查询范围
    getStatusVal2(val) {
      this.searchForm.belo_dept_id = val;
    }
  }
};
</script>

<style scoped lang="scss">
.vd_elimg {
  width: 60px;
  height: 60px;
  margin-top: 5px;
}
::v-deep .el-checkbox__inner {
  border: 1px solid #080808;
}
.vd_dis {
  display: flex;
}
.vd_div {
  width: 40px;
  height: 20px;
  border: none;
  margin-right: 5px;
}
</style>
